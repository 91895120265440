import { http } from './http_service';

// Get all order according to customer Id
export function all(customerId){
    return http().get(`/order/customer/${customerId}`);
}
// Get Data for dashboard
export function dashboard(customerId){
    return http().get(`/order/customer/dashboard/${customerId}`);
}
