import { http, httpFile } from './http_service';

// Get all
export function all(){
    return http().get('/product-category-sub');
}
// Get all active
export function allActive(){
    return http().get('/product-category-sub/active');
}
// Get Single
export function single(id){
    return http().get(`/product-category-sub/${id}`);
}
// Add one
export function add(data){
    return http().post('/product-category-sub', data)
}
// Update
export function update(id, data){
    return http().put(`/product-category-sub/${id}`, data)
}
// Delete one
export function deleteSingle(id){
    return http().delete(`/product-category-sub/${id}`);
}