import * as messageService from "../../services/message.service";
import * as productCategoryMainService from '../../services/productCategoryMain.service';


const state = {
    productCategoryMainAll:[],
    productCategoryMainAllActive:[],
};

const getters = {
    gettProductCategoryMainAll:state => (state.productCategoryMainAll),
    gettProductCategoryMainAllActive:state => (state.productCategoryMainAllActive),
};

const actions = {
    async fetchProductCategoryMainAll({ commit } ){
        try {
            const response = await productCategoryMainService.all();

            commit('setProductCategoryMainAll', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchProductCategoryMainAllActive({ commit } ){
        try {
            const response = await productCategoryMainService.allActive();

            commit('setProductCategoryMainAllActive', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
};

const mutations = {
    setProductCategoryMainAll:(state, res) => (
        state.productCategoryMainAll =  res
    ),
    setProductCategoryMainAllActive:(state, res) => (
        state.productCategoryMainAllActive =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}