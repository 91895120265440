import { http, httpFile } from './http_service';

// Get all
export function all(){
    return http().get('/setting');
}
// Update
export function update(id, data){
    return http().put(`/setting/${id}`, data)
}
