import { http } from './http_service';

// Get all
export function all(){
    return http().get('/order');
}
// Get list with pagination
export function listPaginate(params){
    const queryString = Object.keys(params).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');
    return http().get(`/order/list-paginate?${queryString}`);
}

// Get Data for dashboard
export function dashboard(){
    return http().get('/order/dashboard');
}

// Get all active
export function allActive(){
    return http().get('/order/active');
}
// Get Single
export function single(id){
    return http().get(`/order/${id}`);
}
// Add one
export function add(data){
    return http().post('/order', data)
}
// Update
export function update(id, data){
    return http().put(`/order/${id}`, data)
}
// wo-status/{id}
export function updateWOStatus(id){
    return http().put(`/order/wo-status/${id}`)
}
// Delete one
export function deleteSingle(id){
    return http().delete(`/order/${id}`);
}
// Send email confirmation and update
export function sendEmailOrderConfirm(orderId){
    return http().post(`/order/send-email-confirmation-new/${orderId}`)
}
export function sendEmailConfirmationUpdate(orderId){
    return http().post(`/order/send-email-confirmation-update/${orderId}`)
}