
const state = {
    // apiURL:'http://localhost:8000/api/v1',
    // serverPath:'http://localhost:8000'

    // apiURL:'http://api-qtfurniture.samath.com.au/api/v1',
    // serverPath:'http://app-qtfurniture.samath.com.au'

    apiURL:'https://api.qtfurniture.com.au/api/v1',
    serverPaths:'http://app.qtfurniture.com.au'

};

const getters = {
    //
};

const actions = {

};

const mutations = {

};

export default{
    state,
    getters,
    actions,
    mutations,
}
