import * as messageService from "../../services/message.service";
import * as orderService from '../../services/order.service';


const state = {
    orderAll:[],
    dashboard:[],
    orderAllActive:[],
    orderSingle:{},
    orderCurrentId:'',
};

const getters = {
    gettOrderAll:state => (state.orderAll),
    gettOrderAllActive:state => (state.orderAllActive),
    gettOrderSingle:state => (state.orderSingle),
    gettDashboard:state => (state.dashboard),
    gettOrderCurrentId:state => (state.orderCurrentId),
};

const actions = {
    async fetchOrderAll({ commit } ){
        try {
            const response = await orderService.all();

            console.log('call setOrderAll');

            commit('setOrderAll', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchDashboard({ commit } ){
        try {
            const response = await orderService.dashboard();

            commit('setDashboard', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchOrderAllActive({ commit } ){
        try {
            const response = await orderService.allActive();

            commit('setOrderAllActive', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchOrderSingle({ commit }, orderId ){
        try {
            const response = await orderService.single(orderId);

            commit('setOrderSingle', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchOrderCurrentId({ commit }, orderId ){
        try {
            commit('setOrderCurrentId', orderId);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
};

const mutations = {
    setOrderAll:(state, res) => (
        state.orderAll =  res
    ),
    setDashboard:(state, res) => (
        state.dashboard =  res
    ),
    setOrderAllActive:(state, res) => (
        state.orderAllActive =  res
    ),
    setOrderSingle:(state, res) => (
        state.orderSingle =  res
    ),
    setOrderCurrentId:(state, res) => (
        state.orderCurrentId =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}