import * as messageService from "../../services/message.service";
import * as timberService from '../../services/timber.service';


const state = {
    timberAll:[],
    timberAllActive:[],
};

const getters = {
    gettTimberAll:state => (state.timberAll),
    gettTimberAllActive:state => (state.timberAllActive),
};

const actions = {
    async fetchTimberAll({ commit } ){
        try {
            const response = await timberService.all();

            commit('setTimberAll', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchTimberAllActive({ commit } ){
        try {
            const response = await timberService.allActive();

            commit('setTimberAllActive', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
};

const mutations = {
    setTimberAll:(state, res) => (
        state.timberAll =  res
    ),
    setTimberAllActive:(state, res) => (
        state.timberAllActive =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}