import * as messageService from "../../services/message.service";
import * as stainService from '../../services/stain.service';


const state = {
    stainAll:[],
    stainAllActive:[],
};

const getters = {
    gettStainAll:state => (state.stainAll),
    gettStainAllActive:state => (state.stainAllActive),
};

const actions = {
    async fetchStainAll({ commit } ){
        try {
            const response = await stainService.all();

            commit('setStainAll', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchStainAllActive({ commit } ){
        try {
            const response = await stainService.allActive();

            commit('setStainAllActive', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
};

const mutations = {
    setStainAll:(state, res) => (
        state.stainAll =  res
    ),
    setStainAllActive:(state, res) => (
        state.stainAllActive =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}