import { http, httpFile } from './http_service';

// Get all
export function all(){
    return http().get('/customer');
}
// Get all active
export function allActive(){
    return http().get('/customer/active');
}
// Get Single
export function single(id){
    return http().get(`/customer/${id}`);
}
// Add one
export function add(data){
    return http().post('/customer', data)
}
// Update
export function update(id, data){
    return http().put(`/customer/${id}`, data)
}
// Delete one
export function deleteSingle(id){
    return http().delete(`/customer/${id}`);
}
// Send verification-code
export function sendVerificationCode(id){
    return http().get(`/customer/send-verification-code/${id}`);
}