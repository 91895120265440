import * as messageService from "../../services/message.service";
import * as orderCustomerService from '../../services/orderCustomer.service';


const state = {
    orderCustomerAll:[],
    customerDashboard:[],
};

const getters = {
    gettOrderCustomerAll:state => (state.orderCustomerAll),
    gettCustomerDashboard:state => (state.customerDashboard),
};

const actions = {
    async fetchOrderCustomerAll({ commit }, customerId ){
        try {
            const response = await orderCustomerService.all(customerId);

            commit('setOrderCustomerAll', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchCustomerDashboard({ commit }, customerId ){
        try {
            const response = await orderCustomerService.dashboard(customerId);

            commit('setCustomerDashboard', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
};

const mutations = {
    setOrderCustomerAll:(state, res) => (
        state.orderCustomerAll =  res
    ),
    setCustomerDashboard:(state, res) => (
        state.customerDashboard =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}