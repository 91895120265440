import { http, httpFile } from './http_service';

// Get all
export function all(){
    return http().get('/stain');
}
// Get all active
export function allActive(){
    return http().get('/stain/active');
}
// Get Single
export function single(id){
    return http().get(`/stain/${id}`);
}
// Add one
export function add(data){
    return http().post('/stain', data)
}
// Update
export function update(id, data){
    return http().put(`/stain/${id}`, data)
}
// Delete one
export function deleteSingle(id){
    return http().delete(`/stain/${id}`);
}