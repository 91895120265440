import { http } from './http_service';

// Get all
export function all(){
    return http().get('/delivery-addresses');
}
// Get all active
export function allActive(){
    return http().get('/delivery-addresses/active');
}
// Get Single
export function single(id){
    return http().get(`/delivery-addresses/${id}`);
}
// Add one
export function add(data){
    return http().post('/delivery-addresses', data)
}
// Update
export function update(id, data){
    return http().put(`/delivery-addresses/${id}`, data)
}
// Delete one
export function deleteSingle(id){
    return http().delete(`/delivery-addresses/${id}`);
}
// Get all address belongs to customer
export function allCustomerDeliveryAddress(customerId){
    return http().get(`/delivery-addresses/customer/${customerId}`);
}