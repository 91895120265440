import { http, httpFile } from './http_service';

// Get all
export function all(){
    return http().get('/product');
}
// Get list with pagination
export function listPaginate(params){
    const queryString = Object.keys(params).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');
    return http().get(`/product/list-paginate?${queryString}`);
}
// Get all active
export function allActive(){
    return http().get('/product/active');
}
// Get Single
export function single(id){
    return http().get(`/product/${id}`);
}
// Add one
export function add(data){
    return http().post('/product', data)
}
// Update
export function update(id, data){
    return http().put(`/product/${id}`, data)
}
// Delete one
export function deleteSingle(id){
    return http().delete(`/product/${id}`);
}