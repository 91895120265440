import * as messageService from "../../services/message.service";
import * as discountRateService from '../../services/discountRate.service';


const state = {
    discountRateAll:[],
};

const getters = {
    gettDiscountRateAll:state => (state.discountRateAll),
};

const actions = {
    async fetchDiscountRateAll({ commit } ){
        try {
            const response = await discountRateService.all();

            commit('setDiscountRateAll', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
};

const mutations = {
    setDiscountRateAll:(state, res) => (
        state.discountRateAll =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}