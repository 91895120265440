
import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

export function fnSweetAlertDisplay(obj) {
    Vue.swal({
            icon: obj.type,
            title: obj.title,
            html: obj.text,
            footer:obj.footer
        }
    );
};


const Toast = Vue.swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Vue.swal.stopTimer)
      toast.addEventListener('mouseleave', Vue.swal.resumeTimer)
    }
  })
  
export function fnSweetAlertSuccessToast(title, msg) {

        Toast.fire({
            icon: 'success',
            title: title,
            text: msg,
        })
};

export function fnSweetAlertErrorToast(title, msg) {

        Toast.fire({
            icon: 'error',
            title: title,
            text: msg,
        })

};

export function fnSweetAlertInformationToast(title, msg) {

    Toast.fire({
        icon: 'info',
        title: title,
        text: msg,
    })
};

export function fnSweetAlertWrningToast(title, msg) {

    Toast.fire({
        icon: 'warning',
        title: title,
        text: msg,
    })
};

export function fnSweetAlertQuestionToast(title, msg) {

    Toast.fire({
        icon: 'question',
        title: title,
        text: msg,
    })
};

