import * as messageService from "../../services/message.service";
import * as orderstranService from '../../services/orderstran.service';


const state = {
    orderstranAll:[],
    orderstranSingle:{},
    orderstranAllOrderWinp:[],
};

const getters = {
    gettOrderstranAll:state => (state.orderstranAll),
    gettOrderstranAllOrderWinp:state => (state.orderstranAllOrderWinp),
    gettOrderstranSingle:state => (state.orderstranSingle),
};

const actions = {
    async fetchOrderstranAll({ commit } ){
        try {
            const response = await orderstranService.all();

            commit('setOrderstranAll', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchOrderstranAllOrderWinp({ commit } ){
        try {
            const response = await orderstranService.allOrderWinp();

            commit('setOrderstranAllOrderWinp', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchOrderstranSingle({ commit }, id ){
        try {
            const response = await orderstranService.single(id);

            commit('setOrderstranSingle', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
};

const mutations = {
    setOrderstranAll:(state, res) => (
        state.orderstranAll =  res
    ),
    setOrderstranAllOrderWinp:(state, res) => (
        state.orderstranAllOrderWinp =  res
    ),
    setOrderstranSingle:(state, res) => (
        state.orderstranSingle =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}