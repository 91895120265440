import * as messageService from "../../services/message.service";
import * as companyService from '../../services/company.service';


const state = {
    company:{},

};

const getters = {
    getCompany:state => (state.company),
};

const actions = {
    async fetchCompany({ commit } ){

        try {
            const response = await companyService.all();

            console.log('response.data', response);

            commit('setCompany', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
};

const mutations = {
    setCompany:(state, res) => (
        state.company =  res
    )
};

export default{
    state,
    getters,
    actions,
    mutations,
}
