import * as auth from '../../services/auth.service';

const state = {
    isLoggrdIn:null,
    profile:{},
};

const getters = {
    gettUserProfile:state => (state.profile),
};

const actions = {
    fetchAuthenticate({commit}){
        let userInfo='';

        const isLogged = auth.isLoggedIn();

        if(isLogged){
             userInfo = auth.getUserFromToken();
        }else{
            userInfo = {};
        }

        commit('authenticate', userInfo);

    },
};

const mutations = {

    authenticate:(state, res) => (
        state.profile =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}