<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <a href="#" class="brand-link pt1000 pb2500">
      <img
        src="../../assets/img/logo.png"
        alt="Vehile solutions logo"
        class="brand-image"
      />
    </a>

    <div class="sidebar">
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img
            src="../../assets/img/person-2.png"
            class="img-circle elevation-2"
            alt="User Image"
          />
        </div>
        <div class="info">
          <a href="#" class="d-block">{{ userObj.firstName }}</a>
        </div>
      </div>
      <nav class="mt-2 menuSection">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li class="nav-item notDashboard">
            <div @click="fnMenu('dashboard')">
              <router-link to="/admin" class="nav-link">
                <i class="nav-icon fas fa-tachometer-alt"></i>
                <p>Dashboard</p>
              </router-link>
            </div>
          </li>

          <li class="nav-item">
            <router-link to="/admin/customers" class="nav-link">
              <i class="nav-icon fas fa-user-friends"></i>
              <p>Customers</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/admin/order" class="nav-link">
              <i class="nav-icon fas fa-folder-plus"></i>
              <p>Orders</p>
            </router-link>
          </li>

          <!-- <li class="nav-item">
            <router-link to="/admin/work-order-list" class="nav-link">
              <i class="nav-icon fas fa-cog"></i>
              <p>Work orders</p>
            </router-link>
          </li> -->
          <li class="nav-item">
            <router-link to="/admin/work-order-list-new" class="nav-link">
              <i class="nav-icon fas fa-cog"></i>
              <p>Work orders</p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/admin/product" class="nav-link active">
              <i class="nav-icon fab fa-product-hunt"></i>
              <p>
                Products
                <i class="right fas fa-angle-left"></i>
              </p>
            </router-link>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/admin/product" class="nav-link">
                  <div class="d-flex flex-column pl2000">
                    <p class="mb-0">Product</p>
                  </div>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/product-category-main" class="nav-link">
                  <div class="d-flex flex-column pl2000">
                    <p class="mb-0">Category main</p>
                  </div>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/product-category-sub" class="nav-link">
                  <div class="d-flex flex-column pl2000">
                    <p class="mb-0">Category sub</p>
                  </div>
                </router-link>
              </li>
            </ul>
          </li>

          <li
            class="nav-item"
            v-bind:class="{
              'menu-is-opening': isActive2,
              'menu-open': isActive2,
            }"
          >
            <div @click="fnMenu('reports')">
              <router-link to="/admin/report-sales-details" class="nav-link">
                <i class="nav-icon fas fa-file-contract"></i>
                <p>
                  Reports
                  <i class="right fas fa-angle-left"></i>
                </p>
              </router-link>
            </div>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/admin/report-sales-details" class="nav-link">
                  <div class="d-flex flex-column pl2000">
                    <p class="mb-0">Sales details</p>
                    <p class="fs-700 mb-0 m-t900">Based on Order Date</p>
                  </div>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/admin/report-sales-details-item-based"
                  class="nav-link"
                >
                  <div class="d-flex flex-column pl2000">
                    <p class="mb-0">Sales details item</p>
                    <p class="fs-700 mb-0 m-t900">Based on Order Date</p>
                  </div>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/admin/report-sales-details-invoice"
                  class="nav-link"
                >
                  <div class="d-flex flex-column pl2000">
                    <p class="mb-0">Sales details</p>
                    <p class="fs-700 mb-0 m-t900">Based on Invoice Date</p>
                  </div>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <router-link to="/admin/users" class="nav-link">
              <i class="nav-icon fas fa-users-cog"></i>
              <p>Users</p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/admin/timber" class="nav-link active">
              <i class="nav-icon fas fa-tools"></i>
              <p>
                Settings
                <i class="right fas fa-angle-left"></i>
              </p>
            </router-link>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/admin/timber" class="nav-link">
                  <div class="d-flex flex-column pl2000">
                    <p class="mb-0">Timber</p>
                  </div>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/stain" class="nav-link">
                  <div class="d-flex flex-column pl2000">
                    <p class="mb-0">Stain</p>
                  </div>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin/discount-rate" class="nav-link">
                  <div class="d-flex flex-column pl2000">
                    <p class="mb-0">Discount ratet</p>
                  </div>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>
<script>
import { mapGetters } from "vuex";
import * as authService from "../../services/auth.service";
export default {
  data() {
    return {
      userObj: {},
      displayType: "none",
      isActive1: false,
      isActive2: false,
    };
  },
  computed: {
    ...mapGetters(["gettUserProfile"]),
  },
  methods: {
    fnMenu(vv) {
      if (vv === "profile") {
        this.isActive1 = !this.isActive1;
        this.isActive2 = false;
      } else if (vv === "reports") {
        this.isActive2 = !this.isActive2;
        this.isActive1 = false;
      } else {
        this.isActive1 = false;
        this.isActive2 = false;
      }
    },
  },
  created() {
    this.userObj = authService.getUserFromToken();
  },
};
</script>
