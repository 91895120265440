import { http, httpFile } from './http_service';

// Get all
export function all(){
    return http().get('/timber');
}
// Get all active
export function allActive(){
    return http().get('/timber/active');
}
// Get Single
export function single(id){
    return http().get(`/timber/${id}`);
}
// Add one
export function add(data){
    return http().post('/timber', data)
}
// Update
export function update(id, data){
    return http().put(`/timber/${id}`, data)
}
// Delete one
export function deleteSingle(id){
    return http().delete(`/timber/${id}`);
}