import * as messageService from "../../services/message.service";
import * as deliveryAddressesService from '../../services/deliveryAddresses';


const state = {
    customerDeliveryAddressAll:[],
    customerDeliveryAddressAllActive:[],
};

const getters = {
    gettCustomerDeliveryAddressAll:state => (state.customerDeliveryAddressAll),
    gettCustomerDeliveryAddressAllActive:state => (state.customerDeliveryAddressAllActive),
};

const actions = {
    async fetchCustomerDeliveryAddressAll({ commit }, customerId){
        try {
            const response = await deliveryAddressesService.allCustomerDeliveryAddress(customerId);

            commit('setCustomerDeliveryAddressAll', response.data);
            
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchCustomerDeliveryAddressAllActive({ commit }, customerId ){
        try {
            const response = await deliveryAddressesService.allActive();

            commit('setCustomerDeliveryAddressAllActive', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
};

const mutations = {
    setCustomerDeliveryAddressAll:(state, res) => (
        state.customerDeliveryAddressAll =  res
    ),
    setCustomerDeliveryAddressAllActive:(state, res) => (
        state.customerDeliveryAddressAllActive =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}