import Vue from 'vue';
import VueRouter from 'vue-router';
import homeAdmin from '../views/admin/Home.vue';
import homeCustomer from '../views/customer/Home.vue';
import * as auth from '../services/auth.service';


Vue.use(VueRouter)

const routes = [
  {
    path:'/admin',
    name:'admin',
    component:homeAdmin,
    children:[
        {
            path: '',
            name: 'dashboardAdmin',
            component:() => import('../views/admin/Dashboard.vue'),
        },
        {
          path: 'users',
          name: 'usersAdmin',
          component:() => import('../views/admin/Users.vue'),
        },
        {
          path: 'profile',
          name: 'profileAdmin',
          component:() => import('../views/admin/Profile.vue'),
        },
        {
          path: 'password-change',
          name: 'passwordChangeAdmin',
          component:() => import('../views/admin/PasswordChange.vue'),
        },
        {
          path: 'timber',
          name: 'timberAdmin',
          component:() => import('../views/admin/Timber.vue'),
        },
        {
          path: 'stain',
          name: 'stainAdmin',
          component:() => import('../views/admin/Stain.vue'),
        },
        {
          path: 'discount-rate',
          name: 'discountRateAdmin',
          component:() => import('../views/admin/DiscountRate.vue'),
        },
        {
          path: 'customers',
          name: 'customersRateAdmin',
          component:() => import('../views/admin/Customers.vue'),
        },
        {
          path: 'delivery-addresses/:customerId',
          name: 'deliveryAddressesAdmin',
          component:() => import('../views/admin/DeliveryAddresses.vue'),
        },
        {
          path: 'product',
          name: 'ProductListAdmin',
          component:() => import('../views/admin/ProductList.vue'),
        },

        
        {
          path: 'product-category-main',
          name: 'productCategoryMainAdmin',
          component:() => import('../views/admin/ProductCategoryMain.vue'),
        },
        {
          path: 'product-category-sub',
          name: 'productCategorySubAdmin',
          component:() => import('../views/admin/ProductCategorySub.vue'),
        },
        {
          path: 'order',
          name: 'OrderAdmin',
          component:() => import('../views/admin/Order.vue'),
        },


        
        {
          path: 'order-new',
          name: 'orderNewAdmin',
          component:() => import('../views/admin/OrderNew.vue'),
        },
        {
          path: 'order-edit/:orderId',
          name: 'orderEditAdmin',
          component:() => import('../views/admin/OrderEdit.vue'),
        },
        {
          path: 'work-order-list',
          name: 'WorkOrderListAdmin',
          component:() => import('../views/admin/WorkOrderList.vue'),
        },
        {
          path: 'work-order-list-new',
          name: 'WorkOrderListNewAdmin',
          component:() => import('../views/admin/WorkOrderListNew.vue'),
        },

        


        {
          path: 'work-order/:orderId',
          name: 'workOrderAdmin',
          component:() => import('../views/admin/WorkOrder.vue'),
        },
        {
          path: 'work-order-print/:workOrderId',
          name: 'workOrderPrintAdmin',
          component:() => import('../views/admin/WorkOrderPrint.vue'),
        },
        {
          path: 'invoice/:orderId',
          name: 'invoiceAdmin',
          component:() => import('../views/admin/Invoice.vue'),
        },
        {
          path: 'invoice-print/:orderId',
          name: 'invoicePrintAdmin',
          component:() => import('../views/admin/InvoicePrint.vue'),
        },
        
        {
          path: 'report-sales-details',
          name: 'reportSalesDetailsAdmin',
          component:() => import('../views/reports/SalesDetails.vue'),
        },
        {
          path: 'report-sales-details-item-based',
          name: 'reportSalesDetailsItemBasedAdmin',
          component:() => import('../views/reports/SalesDetailsItemBased.vue'),
        },

        
        {
          path: 'report-sales-details-invoice',
          name: 'reportSalesDetailsInvoiceAdmin',
          component:() => import('../views/reports/SalesDetailsInvoiceBased.vue'),
        },
    ],
    beforeEnter(to, from, next){
      if(!auth.isLoggedIn()){
          next('/login');
      }else{
          next();
      }
    }
  },

  // Customer
  {
    path:'/customer',
    name:'customer',
    component:homeCustomer,
    children:[
        {
            path: '',
            name: 'dashboardCustomer',
            component:() => import('../views/customer/Dashboard.vue'),
        },
        {
          path: 'order',
          name: 'orderCustomer',
          component:() => import('../views/customer/Order.vue'),
        },
        {
          path: 'order-view/:orderId',
          name: 'orderViewCustomer',
          component:() => import('../views/customer/OrderView.vue'),
        },
        {
          path: 'invoice-print/:orderId',
          name: 'invoicePrintCustomer',
          component:() => import('../views/customer/InvoicePrint.vue'),
        },

        
        // {
        //   path: 'users',
        //   name: 'usersCustomer',
        //   component:() => import('../views/customer/Users.vue'),
        // },
        // {
        //   path: 'profile',
        //   name: 'profileCustomer',
        //   component:() => import('../views/customer/Profile.vue'),
        // },
        // {
        //   path: 'password-change',
        //   name: 'passwordChangeCustomer',
        //   component:() => import('../views/customer/PasswordChange.vue'),
        // },
    ],
    beforeEnter(to, from, next){
      if(!auth.isLoggedIn()){
          next('/login');
      }else{
          next();
      }
    }
  },

  // Login 
  {
    path: '/',
    name: 'login',
    component:() => import('../views/authentication/Login.vue'),
  },
  // Login 
  {
    path: '/login',
    name: 'login',
    component:() => import('../views/authentication/Login.vue'),
  },
  // Register
  {
    path: '/register',
    name: 'Register',
    component:() => import('../views/authentication/Register.vue'),
  },
  // Forget password
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component:() => import('../views/authentication/ForgotPassword.vue'),
  },







  // Forget password welcome message
  {
      path: '/forgot-password-welcome-message',
      name: 'forgotPasswordWelcomeMessage',
      component:() => import('../views/authentication/ForgotPasswordWelcomMessage.vue'),
  },
  // Forget password send with token
  {
      path: '/reset-password/:token',
      name: 'resetPassword',
      component:() => import('../views/authentication/ResetPassword.vue'),
  },
  {
    path: '/verify-account/:code',
    name: 'verifyAccount',
    component:() => import('../views/authentication/VerifyAccount.vue'),
  },

  // Account verification message
  {
    path: '/verification-message',
    name: 'verificationMessage',
    component:() => import('../views/authentication/VerificationMessage.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
