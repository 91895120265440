import * as messageService from "../../services/message.service";
import * as customerService from '../../services/customer.service';


const state = {
    customerAll:[],
    customerAllActive:[],
    customerSingle:{},
};

const getters = {
    gettCustomerAll:state => (state.customerAll),
    gettCustomerAllActive:state => (state.customerAllActive),
    gettCustomerSingle:state => (state.customerSingle),
};

const actions = {
    async fetchCustomerAll({ commit } ){
        
        try {
            const response = await customerService.all();

            console.log('response.data', response);

            commit('setCustomerAll', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchCustomerAllActive({ commit } ){
        try {
            const response = await customerService.allActive();

            commit('setCustomerAllActive', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchCustomerSingle({ commit }, id ){
        try {
            const response = await customerService.single(id);

            commit('setCustomerSingle', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
};

const mutations = {
    setCustomerAll:(state, res) => (
        state.customerAll =  res
    ),
    setCustomerAllActive:(state, res) => (
        state.customerAllActive =  res
    ),
    setCustomerSingle:(state, res) => (
        state.customerSingle =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}