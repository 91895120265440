import { http, httpFile } from './http_service';

// Get all
export function all(){
    return http().get('/discount-rate');
}
// Get Single
export function single(id){
    return http().get(`/discount-rate/${id}`);
}
// Add one
export function add(data){
    return http().post('/discount-rate', data)
}
// Update
export function update(id, data){
    return http().put(`/discount-rate/${id}`, data)
}
// Delete one
export function deleteSingle(id){
    return http().delete(`/discount-rate/${id}`);
}