import * as messageService from "../../services/message.service";
import * as userService from '../../services/user.service';


const state = {
    userList:[],
    userById:{},
};

const getters = {
    gettUserAll:state => (state.userList),
    gettUserById:state => (state.userById),
};

const actions = {
    async fetchUserAll({ commit } ){
        try {
            const response = await userService.userAll();

            commit('setUserAll', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchUserById({commit}, id){
        try {
            const response = await userService.userById(id);
            commit('setUserById', response.data.user);
        } catch (error) {
            console.log(error.response);
            console.log(error.response.data);

            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    }
};

const mutations = {
    setUserAll:(state, res) => (
        state.userList =  res
    ),
    setUserById:(state, res) => (
        state.userFromId =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}