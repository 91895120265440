import { http, httpFile } from './http_service';

// Get all
export function all(){
    return http().get('/title');
}
// Get all active
export function allActive(){
    return http().get('/title/active');
}
// Get Single
export function single(id){
    return http().get(`/title/${id}`);
}
// Add one
export function add(data){
    return http().post('/title', data)
}
// Update
export function update(id, data){
    return http().put(`/title/${id}`, data)
}
// Delete one
export function deleteSingle(id){
    return http().delete(`/title/${id}`);
}