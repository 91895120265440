import * as messageService from "../../services/message.service";
import * as productCategorySubService from '../../services/productCategorySub.service';


const state = {
    productCategorySubAll:[],
    productCategorySubAllActive:[],
};

const getters = {
    gettProductCategorySubAll:state => (state.productCategorySubAll),
    gettProductCategorySubAllActive:state => (state.productCategorySubAllActive),
};

const actions = {
    async fetchProductCategorySubAll({ commit } ){
        try {
            const response = await productCategorySubService.all();

            commit('setProductCategorySubAll', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchProductCategorySubAllActive({ commit } ){
        try {
            const response = await productCategorySubService.allActive();

            commit('setProductCategorySubAllActive', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
};

const mutations = {
    setProductCategorySubAll:(state, res) => (
        state.productCategorySubAll =  res
    ),
    setProductCategorySubAllActive:(state, res) => (
        state.productCategorySubAllActive =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}