import { http, httpFile } from './http_service';

// Get all
export function all(){
    return http().get('/designation');
}
// Get all active
export function allActive(){
    return http().get('/designation/active');
}
// Get Single
export function single(id){
    return http().get(`/designation/${id}`);
}
// Add one
export function add(data){
    return http().post('/designation', data)
}
// Update
export function update(id, data){
    return http().put(`/designation/${id}`, data)
}
// Delete one
export function deleteSingle(id){
    return http().delete(`/designation/${id}`);
}