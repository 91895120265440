import * as messageService from "../../services/message.service";
import * as titleService from '../../services/title.service';


const state = {
    titleAll:[],
    titleAllActive:[],
};

const getters = {
    gettTitleAll:state => (state.titleAll),
    gettTitleAllActive:state => (state.titleAllActive),
};

const actions = {
    async fetchTitleAll({ commit } ){
        try {
            const response = await titleService.all();

            commit('setTitleAll', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
    async fetchTitleAllActive({ commit } ){
        try {
            const response = await titleService.allActive();

            commit('setTitleAllActive', response.data);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
};

const mutations = {
    setTitleAll:(state, res) => (
        state.titleAll =  res
    ),
    setTitleAllActive:(state, res) => (
        state.titleAllActive =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}