import { http, httpFile } from './http_service';


export function userAll(){
    return http().get('/user');
}
export function userAdd(data){
    return httpFile().post('/user', data)
}
export function userUpdate(id, data){
    return httpFile().post(`/user/${id}`, data)
}



export function deleteUser(id){
    return http().delete(`/user/${id}`);
}


// User information form ID
export function userById(id){
    return http().get(`/admin/user-from-id/${id}`);
}