import Vue from 'vue'
import Vuex from 'vuex'

import baseModule from "./modules/base.module";
import authModule from "./modules/auth.module";
import userModule from "./modules/user.module";
import timberModule from "./modules/timber.module";
import stainModule from "./modules/stain.module";
import discountRateModule from "./modules/discountRate.module";
import customerModule from "./modules/customer.module";
import titleModule from "./modules/title.module";
import designationModule from "./modules/designation.module";
import settingModule from './modules/setting.module';
import deliveryAddressesModule from './modules/deliveryAddresses.module';
import productModule from './modules/product.module';
import productCategoryMainModule from './modules/productCategoryMain.module';
import productCategorySubModule from './modules/productCategorySub.module';
import orderModule from './modules/order.module';
import orderCustomerModule from './modules/orderCustomer.module';
import orderstranModule from './modules/orderstran.module';
import allSettingsModule from './modules/allSettings.module';
import companyModule from "./modules/company.module";




Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    baseModule,
    authModule,
    userModule,
    timberModule,
    stainModule,
    discountRateModule,
    customerModule,
    titleModule,
    designationModule,
    settingModule,
    deliveryAddressesModule,
    productModule,
    productCategoryMainModule,
    productCategorySubModule,
    orderModule,
    orderCustomerModule,
    orderstranModule,
    allSettingsModule,
    companyModule,
  }
})
