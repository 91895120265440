import * as messageService from "../../services/message.service";
import * as designationService from '../../services/designation.service';
import * as titleService from '../../services/title.service';



const state = {
    allSettings:{},
};

const getters = {
    gettAllSettings:state => (state.allSettings),
};

const actions = {
    async fetchAllSettings({ commit } ){
        try {
            const response1 = await  designationService.allActive();
            const response2 = await  titleService.allActive();

            const obj = {
                "designationAllActive":response1.data,
                "titleAllActive":response2.data
            }

            commit('setAllSettings', obj);
        } catch (error) {
            const obj = {
                "type":'error',
                "title":error.response.data.error.name,
                "text":error.response.data.error.message,
                "footer":'Error code: ' + error.response.data.error.statusCode,
            }

            messageService.fnSweetAlertDisplay(obj);
        }
    },
};

const mutations = {
    setAllSettings:(state, res) => (
        state.allSettings =  res
    ),
};

export default{
    state,
    getters,
    actions,
    mutations,
}