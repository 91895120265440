import { http } from './http_service';

// get all
export function all(){
    return http().get('/orderstran')
}

// Get list with pagination
export function listPaginate(params){
    const queryString = Object.keys(params).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');
    return http().get(`/orderstran/list-paginate?${queryString}`);
}


// get all-order-work in progress
export function allOrderWinp(){
    return http().get('/orderstran/all-order-winp')
}
// get Single
export function single(id){
    return http().get(`/orderstran/${id}`)
}
// Add one
export function add(data){
    return http().post('/orderstran', data)
}
// Update
export function update(id, data){
    return http().put(`/orderstran/${id}`, data)
}
// Process-status/{id}
export function updateProcessStatus(id, data){
    return http().put(`/orderstran/update-process-status/${id}`, data)
}
// Process all update before invoice
export function updateProcessStatusBeforeInvoice(id){
    return http().put(`/orderstran/update-process-status-before-invoice/${id}`)
}

// Delete one
export function deleteSingle(id){
    return http().delete(`/orderstran/${id}`);
}